@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}
body {
  height: 100%;
}
#root {
  height: 100%;
}
.App {
  height: 100%;
}
